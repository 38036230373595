import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const RegisterPage = () => {
  const [companyName, setCompanyName] = useState('');
  const [role, setRole] = useState('employee'); // Défaut : "employee"
  const [prenom, setPrenom] = useState(''); // Prénom
  const [nom, setNom] = useState(''); // Nom
  const [email, setEmail] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  // Fonction pour évaluer la force du mot de passe
  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 12) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setMotDePasse(newPassword);
    evaluatePasswordStrength(newPassword);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return 'bg-danger'; // Rouge (faible)
      case 2:
        return 'bg-warning'; // Jaune (moyen)
      case 3:
      case 4:
        return 'bg-info'; // Bleu (bon)
      case 5:
        return 'bg-success'; // Vert (très fort)
      default:
        return '';
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (motDePasse !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    try {
      const response = await axios.post('https://dev.mysheduloom.fr/api/register', {
        companyName,
        role,
        prenom, // Utilisation de prenom et nom ici
        nom, // Utilisation de nom ici
        email,
        motDePasse,
      });

      if (response.data.message === "Utilisateur enregistré avec succès") {
        setSuccessMessage("Inscription réussie !");
        setTimeout(() => {
          navigate('/login');
        }, 2000); // Redirection après 2 secondes
      } else {
        setErrorMessage(response.data.message || "Une erreur est survenue.");
      }
    } catch (error) {
      setErrorMessage("Erreur réseau. Veuillez vérifier votre connexion.");
    }
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#f4f4f9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '60px',
      }}
    >
      <div
        className="card shadow-lg"
        style={{
          maxWidth: '500px',
          width: '100%',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <h2 className="text-center mb-4">Créer un Nouveau Compte</h2>
        <form onSubmit={handleRegister} className="register-form">
          <div className="form-group mb-3">
            <label htmlFor="companyName">Nom de l'Entreprise</label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="role">Rôle</label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="form-control"
              required
            >
              <option value="employee">Employé</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="prenom">Prénom</label>
            <input
              type="text"
              id="prenom"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="nom">Nom</label>
            <input
              type="text"
              id="nom"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="motDePasse">Mot de Passe</label>
            <input
              type="password"
              id="motDePasse"
              value={motDePasse}
              onChange={handlePasswordChange}
              className="form-control"
              required
            />
            <div className="progress mt-2">
              <div
                className={`progress-bar ${getPasswordStrengthColor()}`}
                role="progressbar"
                style={{ width: `${(passwordStrength / 5) * 100}%` }}
              ></div>
            </div>
            <small>
              Force du mot de passe : {passwordStrength}/5 (min. 12 caractères, majuscules, chiffres,
              caractères spéciaux)
            </small>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="confirmPassword">Confirmer le Mot de Passe</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {!passwordMatch && <p className="text-danger text-center">Les mots de passe ne correspondent pas !</p>}
          {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
          {successMessage && <p className="text-success text-center">{successMessage}</p>}
          <button type="submit" className="btn btn-primary w-100 py-2 mt-3">
            S'inscrire
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
