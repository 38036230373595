import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert, Card } from 'react-bootstrap'; // Importer Card de Bootstrap

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [contactReason, setContactReason] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();

  const handleContactRequest = async (e) => {
    e.preventDefault();
    console.log('Contact request submitted:', { name, email, company, message, contactReason });

    try {
      await axios.post('https://mysheduloom_backend.local/contactRequest.php', {
        name,
        email,
        company,
        message,
        contactReason,
      });

      setShowSuccess(true);
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire de contact', error);
    }
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="container min-vh-100 d-flex flex-column justify-content-center" style={{ paddingTop: '80px' }}>
      <div className="row justify-content-center w-100">
        <div className="col-md-8 col-lg-6">
          {/* Card pour le formulaire */}
          <Card className="shadow-sm" style={{ padding: '20px' }}>
            <h2 className="text-center mb-4">Contactez-nous</h2>
            <p className="text-center mb-4">
              Veuillez remplir le formulaire ci-dessous pour nous contacter. Nous reviendrons vers vous dès que possible.
            </p>

            <form onSubmit={handleContactRequest} className="d-flex flex-column">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Nom Complet</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="Entrez votre nom complet"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">Adresse Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Entrez votre adresse email"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="company" className="form-label">Nom de l'Entreprise (facultatif)</label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Entrez le nom de votre entreprise (facultatif)"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="contactReason" className="form-label">Raison de votre contact</label>
                <select
                  className="form-select"
                  id="contactReason"
                  value={contactReason}
                  onChange={(e) => setContactReason(e.target.value)}
                  required
                >
                  <option value="">Sélectionnez une option</option>
                  <option value="Support technique">Support technique</option>
                  <option value="Demande d'information">Demande d'information</option>
                  <option value="Partenariat">Partenariat</option>
                  <option value="Demande de demo">Demande de demo</option>
                  <option value="Autre">Autre</option>
                </select>
              </div>

              <div className="d-flex flex-column flex-grow-1">
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    placeholder="Entrez votre message ou questions"
                    rows="5"
                    style={{ resize: 'vertical' }}
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-primary w-100 mt-3 mb-4">Envoyer</button>

              <div className="d-grid">
                <button onClick={handleGoHome} className="btn btn-secondary">Retour à l'Accueil</button>
              </div>
            </form>

            {showSuccess && (
              <Alert variant="success" className="mt-4">
                <strong>Merci !</strong> Votre demande a été envoyée. Nous reviendrons vers vous sous peu.
              </Alert>
            )}
          </Card>
        </div>
      </div>
      {/* Espace de 20px avant le footer */}
      <div style={{ height: '20px' }}></div>
    </div>
  );
};

export default ContactPage;
