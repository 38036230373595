import React from 'react';

// Importez les icônes depuis le dossier d'actifs
import facebookIcon from '../assets/icons/facebook.svg';
import twitterIcon from '../assets/icons/twitter.svg';
import linkedinIcon from '../assets/icons/linkedin.svg';
import instagramIcon from '../assets/icons/instagram.svg';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <div className="container">
        <div className="row text-center text-md-start">
          {/* Informations sur l'entreprise */}
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            <h4 className="mb-3">Informations sur l'entreprise</h4>
            <p>&copy; 2024 MySheduLoom</p>
            <p>Adresse : 91B chemin de la corderie, Pont-à-mousson, France</p>
            <p>Téléphone : +33 7 49 00 76 78</p>
            <p>Email : contact@mysheduloom.fr</p>
          </div>

          {/* Liens Utiles */}
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            <h4 className="mb-3">Liens Utiles</h4>
            <ul className="list-unstyled">
              <li><a href="/terms" className="text-white text-decoration-none">Conditions d'utilisation</a></li>
              <li><a href="/privacy" className="text-white text-decoration-none">Politique de confidentialité</a></li>
              <li><a href="/avis" className="text-white text-decoration-none">Donner votre avis</a></li>
            </ul>
          </div>

          {/* Réseaux Sociaux */}
          <div className="col-12 col-md-4">
            <h4 className="mb-3">Réseaux Sociaux</h4>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="me-3">
                <img src={facebookIcon} alt="Facebook" width="30" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="me-3">
                <img src={twitterIcon} alt="Twitter" width="30" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="me-3">
                <img src={linkedinIcon} alt="LinkedIn" width="30" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={instagramIcon} alt="Instagram" width="30" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
