import React from 'react';

const PricingCard = ({ plan }) => {
  const getColor = (price) => {
    if (price <= 100) return '#00B4D8';
    if (price <= 200) return '#00C897';
    if (price <= 250) return '#FF9F00';
    return '#F23C29';
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-3">
      <div className="card text-center shadow-sm h-100" style={{ backgroundColor: getColor(plan.price) }}>
        <div className="card-body">
          <h5 className="card-title">{plan.duration}</h5>
          <p className="card-text fs-4">
            <strong>{plan.price}€</strong> / utilisateur
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
