// GarantieCard.js
import React from 'react';

const GarantieCard = ({ title, text }) => (
  <div className="mt-3 col-12 col-sm-6 col-md-4 col-lg-4">
    <div className="card h-100 text-center">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{text}</p>
      </div>
    </div>
  </div>
);

export default GarantieCard;
