// src/pages/ChangePasswordPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ChangePasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    calculatePasswordStrength(password);
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const response = await axios.post('https://mysheduloom_backend.local/changePassword.php', {
        userId,
        currentPassword,
        newPassword,
      });

      if (response.data.success) {
        setSuccessMessage('Le mot de passe a été changé avec succès.');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordStrength(0);

        // Redirection vers la page du compte après un délai
        setTimeout(() => {
          navigate('/account');
        }, 2000);
      } else {
        setErrorMessage(response.data.message || 'Erreur lors du changement de mot de passe.');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du mot de passe:', error);
      setErrorMessage('Une erreur est survenue, veuillez réessayer.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center mb-4">Changer le Mot de Passe</h2>
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="currentPassword" className="form-label">Mot de passe actuel</label>
              <input
                type="password"
                id="currentPassword"
                className="form-control"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                placeholder="Entrez votre mot de passe actuel"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">Nouveau mot de passe</label>
              <input
                type="password"
                id="newPassword"
                className="form-control"
                value={newPassword}
                onChange={handlePasswordChange}
                required
                placeholder="Entrez votre nouveau mot de passe"
              />
              <div className="mt-2">
                <div className={`progress`}>
                  <div
                    className={`progress-bar progress-bar-striped ${passwordStrength === 4 ? 'bg-success' : passwordStrength === 3 ? 'bg-info' : 'bg-warning'}`}
                    style={{ width: `${passwordStrength * 25}%` }}
                    role="progressbar"
                    aria-valuenow={passwordStrength * 25}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <small className="form-text text-muted mt-1">
                Pour un mot de passe fort, la CNIL recommande au moins 8 caractères, avec des majuscules, des minuscules, des chiffres et des caractères spéciaux.
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">Confirmer le nouveau mot de passe</label>
              <input
                type="password"
                id="confirmPassword"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordMatch(e.target.value === newPassword);
                }}
                required
                placeholder="Confirmez votre nouveau mot de passe"
              />
              {!passwordMatch && <div className="text-danger mt-2">Les mots de passe ne correspondent pas</div>}
            </div>
            <button type="submit" className="btn btn-primary w-100">Changer le mot de passe</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
