import React from 'react';
import CarouselItem from './CarouselItem';
import { useMediaQuery } from 'react-responsive';

const FeatureCarousel = () => {
  const slides = [
    {
      title: 'Authentification Sécurisée',
      description: 'L\'authentification sécurisée est essentielle pour garantir aux employés un accès sécurisé à leur emploi du temps.',
      list: [
        'Système de connexion sécurisé avec utilisation de protocoles HTTPS.',
        'Gestion sécurisée des identifiants avec stockage crypté et utilisation de méthodes de hachage.',
        'Politiques de mot de passe robustes et encouragement des pratiques de sécurité.',
        'Authentification à deux facteurs (2FA) pour renforcer la sécurité.',
        'Gestion des sessions sécurisée avec expiration automatique.',
        'Protection contre les attaques par force brute.',
        'Contrôle d\'accès basé sur les rôles pour une gestion fine des autorisations.',
        'Journalisation des accès avec enregistrement des activités d\'authentification.',
        'Mises à jour régulières et tests de sécurité pour assurer la robustesse.'
      ]
    },
    {
      title: 'Interface Conviviale',
      description: 'Conçue pour offrir une expérience utilisateur intuitive, notre interface propose une disposition claire et une navigation fluide.',
      list: [
        'Disposition claire avec une organisation logique des informations.',
        'Navigation fluide grâce à des menus intuitifs et une hiérarchie logique.',
        'Visualisation graphique avec l\'utilisation d\'éléments visuels avec des codes couleurs.',
        'Options de filtrage et de tri pour une personnalisation de l\'expérience.',
        'Vues multiples (quotidienne, hebdomadaire, mensuelle) pour une perspective adaptée.',
        'Notifications visuelles pour mettre en évidence les informations importantes.',
        'Prise en charge des écrans variés et assistance contextuelle.',
        'Tests utilisateurs réguliers pour améliorer l\'ergonomie et la convivialité.'
      ]
    },
    {
      title: 'Notifications',
      description: 'Notre application intègre un système de notifications complet pour informer les employés des changements d\'emploi du temps et d\'autres informations importantes.',
      list: [
        'Notifications push pour des alertes instantanées.',
        'Paramètres de notification personnalisables pour les utilisateurs.',
        'Notifications par e-mail en complément des notifications push.',
        'Notifications de changements d\'emploi du temps avec détails.',
        'Mécanisme de rétroaction pour évaluer l\'efficacité des notifications.',
        'Centralisation des notifications avec un journal des événements.',
        'Notifications de groupe pour une communication ciblée.',
        'Gestion des urgences avec une indication claire des informations critiques.'
      ]
    },
    {
      title: 'Synchronisation avec le Calendrier',
      description: 'Pour une intégration transparente dans la vie quotidienne des utilisateurs, notre application permet la synchronisation avec les applications de calendrier populaires.',
      list: [
        'Support des formats de calendrier standard comme iCal (ICS).',
        'Fourniture de tutoriels et de guides pour la synchronisation.',
        'Intégration transparente avec les applications de calendrier populaires.'
      ]
    },
    {
      title: 'Possibilité de Demander des Modifications',
      description: 'Offrant une approche collaborative, notre application inclut une fonctionnalité permettant aux employés de proposer des modifications de leur emploi du temps.',
      list: [
        'Formulaire de demande pour les modifications d\'horaire.',
        'Motifs de la demande pour une compréhension contextuelle.',
        'Approbation hiérarchique avec notification aux gestionnaires.',
        'Notifications du statut des demandes pour les employés.',
        'Historique des demandes pour le suivi des activités.',
        'Communication directe entre les employés et les gestionnaires.',
        'Rapports sur les demandes pour une analyse approfondie.'
      ]
    },
    {
      title: 'Historique des Horaires',
      description: 'Notre application garde un historique détaillé des horaires passés, facilitant la référence et le suivi.',
      list: [
        'Enregistrement des modifications d\'horaire avec journal d\'historique.',
        'Consultation de l\'historique par les administrateurs et gestionnaires.',
        'Annotations et commentaires pour fournir des contextes supplémentaires.',
        'Exportation de l\'historique pour des rapports internes.',
        'Sécurité des données avec un accès limité aux utilisateurs autorisés.'
      ]
    },
    {
      title: 'Notifications Push',
      description: 'Grâce aux notifications push, les employés sont informés en temps réel des changements et des informations importantes.',
      list: [
        'Notifications instantanées sur les smartphones et tablettes.',
        'Gestion des préférences de notification par les employés.',
        'Possibilité de programmer les notifications.',
        'Notifications de groupes pour des informations ciblées.'
      ]
    },
    {
      title: 'Interface Mobile Optimisée',
      description: 'L\'application est entièrement optimisée pour les appareils mobiles, garantissant une expérience fluide sur toutes les tailles d\'écrans.',
      list: [
        'Navigation fluide avec une interface mobile-friendly.',
        'Mode sombre et clair pour les préférences d\'affichage.',
        'Réactivité parfaite sur tous les appareils et résolutions.'
      ]
    },
    {
      title: 'Gestion des Equipes',
      description: 'Une gestion facile et intuitive des équipes pour les administrateurs.',
      list: [
        'Création et gestion des équipes avec affectation des membres.',
        'Modification des horaires des équipes avec validation des changements.'
      ]
    }
  ];

 const isMobile = useMediaQuery({ maxWidth: 768 }); // Détection de taille d'écran mobile

  if (isMobile) {
    // Affichage des cartes espacées sur mobile
    return (
      <div id="features" className="container my-4">
        <h2 className="text-center mb-4">Présentation des Fonctionnalités</h2>
        <div className="row">
          {slides.map((slide, index) => (
            <div key={index} className="col-12 mb-4">
              <CarouselItem title={slide.title} description={slide.description} list={slide.list} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Nombre de diapositives = nombre de groupes de 3 éléments dans `slides`
  const slideCount = Math.ceil(slides.length / 3);

  return (
    <div id="features" className="containerFonc">
      <h2 className="text-center mb-4">Présentation des Fonctionnalités</h2>
      <div id="carouselExampleDark" className="carousel carousel-dark slide position-relative" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {Array.from({ length: slideCount }).map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-current={index === 0 ? 'true' : 'false'}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {/* Génération des diapositives avec 3 éléments par diapositive */}
          {Array.from({ length: slideCount }).map((_, index) => {
            const slideGroup = slides.slice(index * 3, index * 3 + 3);
            return (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="10000">
                <div className="d-flex justify-content-center">
                  <div className="row">
                    {slideGroup.map((slide, idx) => (
                      <div key={idx} className="mt-3 col-12 col-sm-6 col-md-4">
                        <CarouselItem title={slide.title} description={slide.description} list={slide.list} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default FeatureCarousel;
