import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SupportRequestPage = () => {
  const [tickets, setTickets] = useState([]);
  const [newTicketSubject, setNewTicketSubject] = useState('');
  const [newTicketDescription, setNewTicketDescription] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [newChatMessage, setNewChatMessage] = useState('');
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  const fetchTickets = async () => {
    try {
      const response = await fetch('https://mysheduloom_backend.local/getTickets.php');
      if (!response.ok) throw new Error('Erreur lors de la récupération des tickets');
      const data = await response.json();
      setTickets(data);
    } catch (error) {
      console.error('Erreur fetchTickets:', error);
    }
  };

  const fetchMessages = async (ticketId) => {
    try {
      const response = await fetch(`https://mysheduloom_backend.local/getMessages.php?ticket_id=${ticketId}`);
      if (!response.ok) throw new Error('Erreur lors de la récupération des messages');
      const data = await response.json();
      setChatMessages(data);
    } catch (error) {
      console.error('Erreur fetchMessages:', error);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const createTicket = async (e) => {
    e.preventDefault();
    if (!newTicketSubject || !newTicketDescription) return;

    const ticketData = {
      sujet: newTicketSubject,
      description: newTicketDescription,
      statut: 'Ouvert',
    };

    try {
      const response = await fetch('https://mysheduloom_backend.local/createTicket.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ticketData),
      });

      if (response.ok) {
        fetchTickets();
        setNewTicketSubject('');
        setNewTicketDescription('');
      } else {
        throw new Error('Erreur lors de la création du ticket');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const closeTicket = async (id) => {
    try {
      const response = await fetch('https://mysheduloom_backend.local/closeTicket.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        fetchTickets();
      } else {
        throw new Error('Erreur lors de la fermeture du ticket');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const deleteTicket = async (id) => {
    try {
      const response = await fetch('https://mysheduloom_backend.local/deleteTicket.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        fetchTickets();
      } else {
        throw new Error('Erreur lors de la suppression du ticket');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const sendChatMessage = async () => {
    if (!newChatMessage || !selectedTicketId) return;

    const messageData = {
      ticket_id: selectedTicketId,
      message: newChatMessage,
      sender: 'user',
    };

    try {
      const response = await fetch('https://mysheduloom_backend.local/sendMessage.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(messageData),
      });

      if (response.ok) {
        setNewChatMessage('');
        fetchMessages(selectedTicketId);
      } else {
        throw new Error('Erreur lors de l\'envoi du message');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Demande de Support</h1>

      <div className="row">
        {/* Liste des tickets */}
        <div className="col-md-6">
          <h2>Historique des Tickets</h2>
          <ul className="list-group">
            {tickets.map(ticket => (
              <li key={ticket.id} className="list-group-item d-flex justify-content-between align-items-start">
                <div>
                  <strong>{ticket.sujet}</strong> - {ticket.statut} <br />
                  <span className="text-muted">{ticket.description}</span>
                </div>
                <div className="btn-group" role="group">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setSelectedTicketId(ticket.id);
                      fetchMessages(ticket.id);
                    }}
                  >
                    Voir
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={() => closeTicket(ticket.id)}>Fermer</button>
                  <button className="btn btn-sm btn-danger" onClick={() => deleteTicket(ticket.id)}>Supprimer</button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Formulaire pour créer un ticket */}
        <div className="col-md-6">
          <h2>Créer un Nouveau Ticket</h2>
          <form onSubmit={createTicket}>
            <div className="mb-3">
              <label htmlFor="ticketSubject" className="form-label">Sujet</label>
              <input
                type="text"
                id="ticketSubject"
                className="form-control"
                value={newTicketSubject}
                onChange={(e) => setNewTicketSubject(e.target.value)}
                placeholder="Sujet du problème"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="ticketDescription" className="form-label">Description</label>
              <textarea
                id="ticketDescription"
                className="form-control"
                value={newTicketDescription}
                onChange={(e) => setNewTicketDescription(e.target.value)}
                placeholder="Décrivez votre problème..."
                rows="3"
                required
              />
            </div>
            <button type="submit" className="btn btn-success w-100">Créer Ticket</button>
          </form>
        </div>
      </div>

      {/* Section de chat */}
      {selectedTicketId && (
        <div className="mt-4">
          <h2>Chat avec un Conseiller</h2>
          <div className="border rounded p-3 mb-3" style={{ height: '200px', overflowY: 'scroll' }}>
            {chatMessages.map(message => (
              <div key={message.id} className={`alert ${message.sender === 'user' ? 'alert-primary' : 'alert-secondary'}`}>
                {message.message}
              </div>
            ))}
          </div>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={newChatMessage}
              onChange={(e) => setNewChatMessage(e.target.value)}
              placeholder="Tapez votre message..."
            />
            <button className="btn btn-primary" onClick={sendChatMessage}>Envoyer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupportRequestPage;
