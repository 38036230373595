import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';

const AccountPage = () => {
  // Déclarez l'état pour les données et les erreurs
  const [accountData, setAccountData] = useState(null);
  const [error, setError] = useState(null);

  // Utilisez useEffect pour récupérer les données du backend au chargement du composant
  useEffect(() => {
    // Remplacez l'URL par l'URL de votre backend pour récupérer les données du compte
    const fetchAccountData = async () => {
      try {
        const response = await axios.get('https://dev.mysheduloom.fr/api/account'); // Exemple d'URL, ajustez selon votre API
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setAccountData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAccountData();
  }, []);

  if (error) {
    return <div className="error">Erreur : {error}</div>;
  }

  if (!accountData) {
    return <div>Chargement des données...</div>;
  }

  return (
    <div className="account-page">
      <h1>Mon Compte</h1>
      <div>
        <h2>Détails du compte</h2>
        <p><strong>Nom :</strong> {accountData.name}</p>
        <p><strong>Email :</strong> {accountData.email}</p>
        <p><strong>ID :</strong> {accountData.id}</p>
        {/* Ajoutez plus de données selon la structure de votre réponse */}
      </div>
    </div>
  );
};

export default AccountPage;
