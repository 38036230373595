import React from 'react';

const CarouselItem = ({ title, description, list }) => {
  return (
    <div className="card h-100 text-center">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        <ul>
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CarouselItem;
