import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/HomeCarrousel.css';

import Accueil from "../components/Accueil";
import Features from "../components/Features";
import Testimonials from '../components/Testimonials';
import PricingPlans from '../components/PricingPlans';
import FeatureCarousel from '../components/FeatureCarousel';
import GarantieSection from '../components/GarantieSection';

const HomePage = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Récupération des témoignages depuis le backend
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        console.log('Début de la récupération des témoignages depuis le backend.');

        const response = await axios.get('https://dev.mysheduloom.fr/api/getTestimonials', {
          params: { limit: 10 },
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.data && Array.isArray(response.data)) {
          if (response.data.length === 0) {
            setErrorMessage('Aucun témoignage disponible pour le moment.');
          } else {
            console.log(`Récupération réussie : ${response.data.length} témoignage(s) reçu(s).`);
            setTestimonials(response.data);
          }
        } else {
          console.warn('Les données reçues ne sont pas dans le format attendu :', response.data);
        }
      } catch (error) {
        if (error.response) {
          console.error(`Erreur du serveur (HTTP ${error.response.status}):`, error.response.data);
        } else if (error.request) {
          console.error('Le backend n\'a pas répondu. Détails de la requête :', error.request);
        } else {
          console.error('Erreur lors de la configuration de la requête :', error.message);
        }
        setErrorMessage('Erreur lors de la récupération des témoignages.');
      } finally {
        console.log('Fin de la tentative de récupération des témoignages.');
      }
    };

    fetchTestimonials();
  }, []); // Le tableau de dépendances est vide, donc l'effet s'exécute uniquement au montage du composant

  const entreprisePlans = [
    { duration: "1 an", price: 98.99, taxe: "HT" },
    { duration: "2 ans", price: 123.74, taxe: "HT" },
    { duration: "3 ans", price: 154.67, taxe: "HT" },
    { duration: "4 ans", price: 193.33, taxe: "HT" },
    { duration: "5 ans", price: 241.67, taxe: "HT" },
  ];

  const collectivitePlans = [
    { duration: "1 an", price: 49.90, taxe: "HT" },
    { duration: "2 ans", price: 62.37, taxe: "HT" },
    { duration: "3 ans", price: 77.96, taxe: "HT" },
    { duration: "4 ans", price: 97.46, taxe: "HT" },
    { duration: "5 ans", price: 121.82, taxe: "HT" },
  ];

  return (
    <div>
      {/* Section d'accueil */}
      <Accueil />

      {/* Section de fonctionnalités */}
      <Features />

      {/* Section Témoignages */}
      <Testimonials testimonials={testimonials} errorMessage={errorMessage} />

      {/* Section pricing */}
      <div id="pricing" className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">Tarif</h2>
          <p className="text-center mb-5">
            Investissez dans la tranquillité d'esprit pour vous et vos équipes. Profitez d’un support continu, le tout à un tarif dégressif selon la durée choisie.
          </p>
          <p className="text-center mb-5">Tarif pour les entreprises (HT)</p>
          <PricingPlans title="Tarif pour les entreprises" plans={entreprisePlans} />

          <p className="text-center mb-5"><br />Tarif pour les collectivités (HT)</p>
          <PricingPlans title="Tarif pour les collectivités" plans={collectivitePlans} />
        </div>
      </div>

      {/* Section Fonctionnalités */}
      <FeatureCarousel />

      {/* Section Garantie de Satisfaction */}
      <GarantieSection />
    </div>
  );
};

export default HomePage;
