import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'bootstrap/dist/css/bootstrap.min.css';

const stripePromise = loadStripe('your-publishable-key-here');

const OrderSoftwarePage = () => {
  const [licenseType, setLicenseType] = useState(1);
  const [licenseCount, setLicenseCount] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('credit');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [availableLicenses, setAvailableLicenses] = useState(0);

  //const stripe = useStripe();
  const elements = useElements();

  const pricePerLicense = [80, 150, 200, 250, 300];
  const totalPrice = pricePerLicense[licenseType - 1] * licenseCount;

  // Récupérer le nombre de licences disponibles depuis l'API
  useEffect(() => {
    const fetchLicenseCount = async () => {
      try {
        const response = await fetch('https://mysheduloom_backend.local/getLicenses.php');
        const data = await response.json();
        setAvailableLicenses(data.licenseCount);
      } catch (error) {
        console.error('Error fetching license count:', error);
      }
    };

    fetchLicenseCount();
  }, []);

  /*const handlePayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setPaymentStatus({ success: false, message: error.message });
      setIsProcessing(false);
      return;
    }

    const paymentResponse = await fetch('https://192.168.1.49/your-server-payment-endpoint', {
      method: 'POST',
      body: JSON.stringify({ paymentMethodId: paymentMethod.id, amount: totalPrice * 100 }),
      headers: { 'Content-Type': 'application/json' },
    });

    const paymentResult = await paymentResponse.json();

    if (paymentResult.success) {
      setPaymentStatus({ success: true, message: 'Paiement effectué avec succès' });
    } else {
      setPaymentStatus({ success: false, message: 'Erreur de paiement. Veuillez réessayer.' });
    }

    setIsProcessing(false);
  };*/

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Commander le Logiciel</h1>
      

      <h2 className="mt-5">Gestion des Licences</h2>
      <div className="license-info">
        <div className="license-details">
          <p>Licences disponibles : {availableLicenses}</p>
        </div>
        <div className="license-download">
          <button className="btn btn-secondary" disabled={availableLicenses <= 0}>
            Télécharger le programme
          </button>
        </div>
      </div>
    </div>
  );
};

export default function StripeWrapper() {
  return (
    <Elements stripe={stripePromise}>
      <OrderSoftwarePage />
    </Elements>
  );
}

