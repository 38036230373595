import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/Header.css';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    setIsAuthenticated(!!userId);
  }, [location]);

  const getCurrentPage = () => {
    switch (location.pathname) {
      case '/login':
        return 'Connexion';
      case '/register':
        return 'Inscription';
      case '/account':
        return 'Mon compte';
      case '/request':
        return 'Contact';
      default:
        return null;
    }
  };

  const currentPage = getCurrentPage();

  const handleLogout = () => {
    localStorage.removeItem('userId');
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark fixed-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand text-white">
          MyScheduLoom
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon text-white"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {currentPage && (
              <li className="nav-item d-none d-lg-block">
                <span className="nav-link active text-white">/ {currentPage}</span>
              </li>
            )}
          </ul>
          <div className="d-flex w-100 justify-content-center d-lg-none">
            {location.pathname !== '/account' && (
              <>
                <button
                  type="button"
                  className="btn btn-outline-light mx-2"
                  onClick={() => navigate('/login')}
                >
                  Connexion
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={() => navigate('/register')}
                >
                  Inscription
                </button>
              </>
            )}
          </div>
          <div className="d-flex d-none d-lg-flex">
            {location.pathname === '/account' ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleLogout}
              >
                Déconnexion
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-outline-light me-2"
                  onClick={() => navigate('/login')}
                >
                  Connexion
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigate('/register')}
                >
                  Inscription
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
