import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AccountPage from './pages/AccountPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import RequestPage from './pages/RequestPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import OrderSoftwarePage from './pages/OrderSoftwarePage';
import SupportRequestPage from './pages/SupportRequestPage';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import LeaveReview from './pages/LeaveReview';
import './styles/App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/request" element={<RequestPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/order-software" element={<OrderSoftwarePage />} />
          <Route path="/support" element={<SupportRequestPage />} />
          <Route path="/avis" element={<LeaveReview />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
