// Features.js
import React from 'react';

const FeatureCard = ({ title, text }) => (
  <div className="col-12 col-md-4 mb-4">
    <div className="card h-100 text-center">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{text}</p>
      </div>
    </div>
  </div>
);

const Features = () => (
  <div id="features" className="py-5 bg-light">
    <div className="container">
      <h2 className="text-center mb-4">Pourquoi Choisir Notre Logiciel ?</h2>
      <div className="row">
        <FeatureCard 
          title="Planification Intelligente"
          text="Optimisez vos projets avec des outils de planification flexibles et intuitifs."
        />
        <FeatureCard 
          title="Collaboration en Temps Réel"
          text="Communiquez et collaborez sans effort avec notre messagerie intégrée."
        />
        <FeatureCard 
          title="Gestion simple des Licences"
          text="Commandez et gérez vos licences selon les besoins de votre équipe."
        />
      </div>
    </div>
  </div>
);

export default Features;
