import React from 'react';
import PricingCard from './PricingCard'; // Composant pour chaque carte de tarif

const PricingPlans = ({ title, plans }) => {
  return (
    <div>
      <div className="row justify-content-center">
        {plans.map((plan, index) => (
          <PricingCard key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
