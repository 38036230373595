// src/pages/ForgotPasswordPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import de Bootstrap

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch('https://mysheduloom_backend.local/reset-password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la réinitialisation du mot de passe');
      }

      // Réponse réussie
      console.log('Password reset link sent');
      alert('Un lien de réinitialisation a été envoyé à votre adresse email');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow-lg p-4">
            <h2 className="text-center mb-4">Réinitialiser votre Mot de Passe</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handlePasswordReset}>
              <div className="form-group">
                <label htmlFor="email">Adresse Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Entrez votre adresse email"
                />
              </div>
              <button 
                type="submit" 
                className="btn btn-primary btn-block mt-3" 
                disabled={loading}
              >
                {loading ? 'Envoi en cours...' : 'Envoyer le lien de réinitialisation'}
              </button>
            </form>
            <div className="mt-3 text-center">
              <Link to="/login" className="btn btn-link">Retour à la connexion</Link>
              <br />
              <Link to="/register" className="btn btn-link">Créer un nouveau compte</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
