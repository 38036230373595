import React from "react";
import { Link } from "react-router-dom";

const Accueil = () => {
  return (
    <div id="Accueil" className="bg-primary text-white text-center py-5">
      <div className="container">
        <h1 className="mt-5 display-4 fw-bold position-relative">
          Transformez votre gestion d’équipe
        </h1>
        <p className="lead">
          Découvrez notre logiciel de gestion d'équipe qui facilite la
          collaboration, la planification et la productivité.
        </p>
        <Link to="/request" className="btn btn-light btn-lg mt-3">
          Prendre contact
        </Link>
      </div>
    </div>
  );
};

export default Accueil;
