// HomePage.js ou GarantieSection.js
import React from 'react';
import GarantieCard from './GarantieCard'; // Assurez-vous que le chemin est correct

const garantieData = [
  {
    title: "Facilité d'utilisation",
    text: "MyScheduLoom s'engage à offrir une interface conviviale et intuitive...",
  },
  {
    title: "Sécurité des Données",
    text: "La sécurité de vos données est notre priorité. Nous mettons en place des mesures avancées...",
  },
  {
    title: "Fonctionnalités puissantes",
    text: "Les fonctionnalités de MyScheduLoom sont conçues pour répondre aux besoins variés...",
  },
  {
    title: "Assistance Réactive",
    text: "Notre équipe de support dévouée est disponible pour répondre à toutes vos questions...",
  },
  {
    title: "Mises à Jour Régulières",
    text: "MyScheduLoom est en constante évolution pour répondre aux besoins changeants...",
  },
  {
    title: "Garantie de Remboursement",
    text: "Si, pour une raison quelconque, un utilisateur n'est pas entièrement satisfait de MyScheduLoom...",
  },
];

const GarantieSection = () => (
  <div id="garantie" className="py-5">
    <div className="container">
      <h2 className="text-center mb-4">Garantie de Satisfaction de MyScheduLoom :</h2>
      <p style={{ marginBottom: '20px' }}>
        Chez MyScheduLoom, notre engagement pour la satisfaction de nos utilisateurs est une priorité absolue...
      </p>

      <div className="row">
        {garantieData.map((card, index) => (
          <GarantieCard key={index} title={card.title} text={card.text} />
        ))}
      </div>

      <p style={{ marginTop: '30px', marginBottom: '50px' }}>
        Notre objectif est de créer une expérience utilisateur exceptionnelle en fournissant un produit fiable, sécurisé et innovant...
      </p>
    </div>
  </div>
);

export default GarantieSection;
