// src/components/Testimonials.js
import React from 'react';

const Testimonials = ({ testimonials }) => {
  return (
    <div id="avis" className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">Ce Que Nos Clients Disent</h2>
        <div className="row">
          {testimonials.length > 0 ? (
            testimonials.map((testimonial, index) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                <div className="card h-100">
                  <div className="card-body">
                    <blockquote className="blockquote">
                      <p>"{testimonial.content}"</p>
                    </blockquote>
                    <footer className="blockquote-footer text-end">
                      <cite>{testimonial.author}</cite>
                    </footer>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">Aucun témoignage disponible pour le moment.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
