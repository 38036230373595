// src/pages/LeaveReview.js
import React, { useState } from 'react';

function LeaveReview() {
  const [message, setMessage] = useState('');
  const [sender, setSender] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page à l'envoi du formulaire

    if (!sender) {
      setStatus('Le nom du participant est requis.');
      return; // Empêche l'envoi si le champ sender est vide
    }

    try {
      // Envoi de la requête POST vers le backend sécurisé
      const response = await fetch('https://mysheduloom_backend.local/submitReview.php', {  // Mise à jour vers HTTPS
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          sender,
        }),
      });

      // Lecture de la réponse brute sous forme de texte pour déboguer
      const textResponse = await response.text(); // Utilise .text() pour obtenir la réponse brute
      console.log("Réponse brute du serveur:", textResponse); // Affiche la réponse brute

      // Vérification que la réponse du serveur est bien un JSON valide
      let result;
      try {
        result = JSON.parse(textResponse); // On essaie de parser la réponse en JSON
      } catch (error) {
        console.error("Erreur lors du parsing de la réponse JSON:", error);
        setStatus('Réponse invalide du serveur.');
        return;
      }

      // Si la réponse du serveur est positive
      if (response.ok) {
        setStatus(result.message || 'Avis envoyé avec succès!');
        setMessage('');
        setSender('');
      } else {
        // En cas d'erreur, on affiche le message d'erreur renvoyé par le serveur
        setStatus(result.message || 'Erreur lors de l\'envoi de l\'avis.');
      }

    } catch (error) {
      // Si une erreur de réseau ou autre est survenue, on l'affiche
      console.error('Erreur:', error);
      setStatus('Erreur de connexion au serveur.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center">Laisser un avis</h2>
          {status && <p className="alert alert-info">{status}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message:</label>
              <textarea
                id="message"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="sender" className="form-label">Nom du participant:</label>
              <input
                id="sender"
                type="text"
                className="form-control"
                value={sender}
                onChange={(e) => setSender(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary w-100">Envoyer l'avis</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LeaveReview;
